/* eslint-disable react/jsx-no-target-blank */
import { Capacitor } from '@capacitor/core'
import { css } from '@emotion/react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, Grid, IconButton, Toolbar, Typography } from '@mui/material'
import { useAuth } from 'auth/authContext'
import classNames from 'classnames'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import logoSrc from '_assets/fire_black_and_white.png'
import { Sidebar } from '_shared/Sidebar'

export interface LayoutProps {
  className?: string
  withBack?: boolean
  noMenu?: boolean
  noAppBarElevation?: boolean
  text?: string
}

export const Layout: FC<LayoutProps> = ({ children, withBack, noMenu, noAppBarElevation, text, className }) => {
  const navigate = useNavigate()
  const {
    state: { user },
  } = useAuth()
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false)

  const isIosCapacitor = 'ios' === Capacitor.getPlatform()

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        padding-top: ${isIosCapacitor ? '32px' : 0};
        padding-bottom: ${isIosCapacitor ? '24px' : 0};
      `}
      className="bg-gray-50 min-h-full w-full"
    >
      {user && <Sidebar isOpen={sidebarOpen} close={() => setSidebarOpen(false)} open={() => setSidebarOpen(true)} />}
      <AppBar
        {...(noAppBarElevation ? { elevation: 0 } : {})}
        position="fixed"
        sx={{ paddingTop: isIosCapacitor ? 4 : 0 }}
      >
        <Toolbar className="flex w-full" variant="dense">
          {withBack ? (
            <IconButton color="inherit" onClick={() => navigate(-1)} edge="start">
              <ChevronLeftIcon />
            </IconButton>
          ) : (
            user && (
              <IconButton
                css={css`
                  margin-right: auto;
                `}
                onClick={() => setSidebarOpen(true)}
                color="inherit"
                aria-label="open drawer"
                edge="end"
              >
                <MenuIcon />
              </IconButton>
            )
          )}
          {text && (
            <Typography className="truncate " variant="subtitle1">
              {text}
            </Typography>
          )}
          <img height={64} width={64} className="m-1 h-8 w-8 ml-auto" src={logoSrc} alt="logo" />
        </Toolbar>
      </AppBar>
      <div
        css={css`
          display: flex;
          flex: 1 0 auto;
          flex-direction: column;
        `}
        className={classNames('pt-12 pb-4', className)}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={10}
            md={8}
            lg={6}
            css={css`
              @media screen and (min-width: 599px) {
                margin-left: auto;
                margin-right: auto;
              }
            `}
          >
            {children}
          </Grid>
        </Grid>
      </div>
      <footer className="bg-gray-100 px-2 py-2 flex-shrink-0 flex justify-between text-sm">
        <a
          className="flex self-center"
          href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
          target="_blank"
          rel="noreferrer"
        >
          Polityka prywatności
        </a>
        <div className="text-gray-400 text-sm text-right">
          Ikony stworzone przez
          <a className="mx-1 underline" href="https://www.freepik.com" title="Freepik">
            Freepik
          </a>
          z
          <a className="ml-1 underline" href="https://www.flaticon.com/" title="Flaticon">
            flaticon.com
          </a>
        </div>
      </footer>
    </div>
  )
}
