import { GroupEpisode, Parish, UserDetails } from '_services'
import { axiosInstance } from '_utils'

export interface GroupCreateRequest {
  name: string
  parish: number
}

export interface Group {
  id: number
  name: string
  active: boolean
  leader: User
  episodeNote: null
  parish: Parish
  createdAt: Date
  updatedAt: Date
  members: UserDetails[]
  invitations: Invitation[]
  groupEpisodes: GroupEpisode[]
}

export interface Invitation {
  id: number
  user: User
  group: number
  acceptanceDate: null
  createdAt: Date
  updatedAt: Date
}

export interface User {
  id: number
  email: string
  provider: string
  confirmed: boolean
  blocked: null
  role: number
  createdAt: Date
  updatedAt: Date
  name: string
  lastName: string
  group: null
  approvalAwaitingGroup: null
}

export interface FindGroupParams {
  diocese?: number
  parish?: number
  name?: string
}

export interface FindGroup extends Group {
  isPending: boolean
}

export const groupService = {
  async find(params: FindGroupParams): Promise<FindGroup[]> {
    const urlParams = new URLSearchParams()
    if (params.diocese) {
      urlParams.append('parish.diocese', params.diocese.toString())
    }
    if (params.parish) {
      urlParams.append('parish', params.parish.toString())
    }
    if (params.name) {
      urlParams.append('name_contains', params.name)
    }
    const { data } = await axiosInstance.get(`groups${urlParams.toString() ? `?${urlParams.toString()}` : ''}`)
    return data
  },
  async findOne(id: number): Promise<Group> {
    const { data } = await axiosInstance.get(`groups/${id}`)
    return data
  },
  async findMy(): Promise<Group[]> {
    const { data } = await axiosInstance.get(`my-groups`)
    return data
  },
  async create(request: GroupCreateRequest): Promise<Group> {
    const { data } = await axiosInstance.post('groups', request)
    return data
  },
  async delete(id: number): Promise<Group> {
    const { data } = await axiosInstance.delete(`groups/${id}`)
    return data
  },
  async deleteMember(groupId: number, memberId: number): Promise<Group> {
    const { data } = await axiosInstance.delete(`groups/${groupId}/delete-member/${memberId}`)
    return data
  },
  async updateEpisodes(id: number, episodeIds: number[]): Promise<void> {
    const { data } = await axiosInstance.post(`groups/${id}/update-episodes`, episodeIds)
    return data
  },
}
