import { Capacitor } from '@capacitor/core'
import { css } from '@emotion/react'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { Avatar, CircularProgress, Divider, List, ListItem, ListItemText, SwipeableDrawer } from '@mui/material'
import { useAuth } from 'auth/authContext'
import { ROLES_TRANSLATIONS } from 'auth/rolesTranslations'
import { RoleType } from 'auth/RoleType'
import { FC, forwardRef, KeyboardEvent, MouseEvent } from 'react'
import { useQuery } from 'react-query'
import { LinkProps, useHref, useLinkClickHandler } from 'react-router-dom'
import { groupService } from '_services'

export interface SidebarProps {
  isOpen: boolean
  close(): void
  open(): void
}

export const Sidebar: FC<SidebarProps> = ({ isOpen, close, open }) => {
  const {
    logout,
    state: { user },
  } = useAuth()

  const { data: groups, isLoading } = useQuery('group', () => groupService.findMy())

  const isIosCapacitor = 'ios' === Capacitor.getPlatform()

  const toggleDrawer = (shouldOpen: boolean) => (event: KeyboardEvent | MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
    ) {
      return
    }
    if (shouldOpen) {
      open()
    } else {
      close()
    }
  }

  const profileSection = (
    <div className="m-4 flex items-center">
      <Avatar className="uppercase">{`${user?.name.charAt(0)}${user?.lastName.charAt(0)}`}</Avatar>
      <div className="ml-2 flex flex-col">
        <span className="text-sm font-medium">{`${user?.name} ${user?.lastName}`}</span>
        {user?.role.name && <span className="text-xs">{ROLES_TRANSLATIONS[user.role.name]}</span>}
      </div>
    </div>
  )

  const leaderContent = (
    <div
      className="w-48 h-full flex flex-col justify-between"
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      css={css`
        padding-top: ${isIosCapacitor ? '32px' : 0};
        padding-bottom: ${isIosCapacitor ? '24px' : 0};
      `}
    >
      <div>
        {profileSection}
        <Divider />
        <List>
          {groups?.map((group) => (
            <ListItem button key={group.id}>
              <MyLink to={`/group/${group.id}`}>
                <ListItemText primary={group.name} />
              </MyLink>
            </ListItem>
          ))}
        </List>
      </div>
      <div>
        <Divider />
        <ListItem>
          <MyLink to="/create-group">
            <ListItemText primary="Utwórz nową grupę" />
          </MyLink>
        </ListItem>
        <Divider />
        <ListItem
          onClick={() => {
            logout()
            close()
          }}
          button
        >
          <ListItemText primary="Wyloguj się" />
          <ExitToAppIcon />
        </ListItem>
      </div>
    </div>
  )

  const memberContent = (
    <div
      className="w-48 h-full flex flex-col justify-between"
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      css={css`
        padding-top: ${isIosCapacitor ? '32px' : 0};
        padding-bottom: ${isIosCapacitor ? '24px' : 0};
      `}
    >
      <div>
        {profileSection}
        <Divider />
        <List>
          {groups?.map((group) => (
            <ListItem button key={group.id}>
              <MyLink className="w-full" to={`/group/${group.id}`}>
                <ListItemText primary={group.name} />
              </MyLink>
            </ListItem>
          ))}
        </List>
      </div>
      <div>
        <Divider />
        <ListItem>
          <MyLink to="/find-group">
            <ListItemText primary="Znajdź grupę" />
          </MyLink>
        </ListItem>
        <Divider />
        <ListItem
          onClick={() => {
            logout()
            close()
          }}
          button
        >
          <ListItemText primary="Wyloguj się" />
          <ExitToAppIcon />
        </ListItem>
      </div>
    </div>
  )

  if (!user) {
    return null
  }

  return (
    <SwipeableDrawer anchor="left" open={isOpen} onClose={() => close()} onOpen={() => open()}>
      {isLoading ? (
        <div
          className="flex justify-center mb-8"
          css={css`
            padding-top: ${isIosCapacitor ? '32px' : 0};
            padding-bottom: ${isIosCapacitor ? '24px' : 0};
          `}
        >
          <CircularProgress color="inherit" />
        </div>
      ) : user.role.name === RoleType.Member ? (
        memberContent
      ) : (
        leaderContent
      )}
    </SwipeableDrawer>
  )
}

const MyLink = forwardRef<any, LinkProps>(({ onClick, replace = false, state, target, to, children, ...rest }, ref) => {
  let href = useHref(to)
  let handleClick = useLinkClickHandler(to, {
    replace,
    state,
    target,
  })

  return (
    <a
      ref={ref}
      className="w-full"
      href={href}
      onClick={(event) => {
        onClick?.(event)
        if (!event.defaultPrevented) {
          handleClick(event)
        }
      }}
      target={target}
      {...rest}
    >
      {children}
    </a>
  )
})
