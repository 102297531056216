import { axiosInstance } from '_utils'

export const invitationService = {
  async accept(id: number): Promise<void> {
    return axiosInstance.get(`invitations/${id}/accept`)
  },
  async decline(id: number): Promise<void> {
    return axiosInstance.get(`invitations/${id}/decline`)
  },
  async create(groupId: number): Promise<void> {
    const { data } = await axiosInstance.post(`invitations`, { group: groupId })
    return data
  },
}
