import { Button as MaterialButton, ButtonProps as MaterialButtonProps, CircularProgress } from '@mui/material'
import { FC, Fragment } from 'react'

export interface ButtonProps extends MaterialButtonProps {
  loading?: boolean
}

export const Button: FC<ButtonProps> = ({ children, loading, disabled, ...rest }) => {
  const endIcon = (
    <Fragment>
      {rest?.endIcon}
      {loading && <CircularProgress color="inherit" size={16} />}
    </Fragment>
  )
  return (
    <MaterialButton disabled={disabled || loading} {...rest} endIcon={endIcon}>
      {children}
    </MaterialButton>
  )
}
