import { Parish } from '_services'
import { axiosInstance } from '_utils'

export interface Diocese {
  id: number
  name: string
  createdAt: Date
  updatedAt: Date
  parishes: Parish[]
}

export const diocesesService = {
  async fetch(): Promise<Diocese[]> {
    const { data } = await axiosInstance.get('dioceses')
    return data
  },
}
