import { css, Global } from '@emotion/react'
import { ThemeProvider } from '@mui/material'
import Slide from '@mui/material/Slide'
import { createTheme } from '@mui/material/styles'
import { AuthProvider } from 'auth/authContext'
import { ErrorBoundary } from 'error-boundary/ErrorBoundary'
import { SnackbarProvider } from 'notistack'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { Router } from 'Router'

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffd453',
    },
    secondary: {
      main: '#26a69a',
    },
  },
})

const queryClient = new QueryClient()

const globalStyles = css`
  @import url('https://fonts.googleapis.com/css2?family=Roboto&family=Work+Sans:wght@100;300;400;500;700&display=swap');

  * {
    font-family: 'Roboto', sans-serif;
    font-family: 'Work Sans', sans-serif;
  }

  ::-webkit-scrollbar {
    background-color: rgb(249, 250, 251);
  }

  ::-webkit-scrollbar-corner {
    background-color: rgb(249, 250, 251);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #d1d5db;
    min-height: 24px;
    border: 3px solid rgb(249, 250, 251);
  }
`

function App() {
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider maxSnack={3} TransitionComponent={(props) => <Slide {...(props as any)} direction="up" />}>
            <BrowserRouter>
              <AuthProvider>
                <Global styles={globalStyles} />
                <Router />
              </AuthProvider>
            </BrowserRouter>
          </SnackbarProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </ErrorBoundary>
  )
}

export default App
