import { CircularProgress } from '@mui/material'
import { useAuth } from 'auth/authContext'
import { ForgotPassword } from 'auth/ForgotPassword'
import { ResetPassword } from 'auth/ResetPassword'
import { RoleType } from 'auth/RoleType'
import { FC, Fragment, lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

const Home = lazy(() => import('home/Home'))
const Login = lazy(() => import('auth/Login'))
const Register = lazy(() => import('auth/Register'))
const EpisodePreview = lazy(() => import('episode-preview/EpisodePreview'))
const GroupCreator = lazy(() => import('group-creator/GroupCreator'))
const GroupFinder = lazy(() => import('group-finder/GroupFinder'))
const GroupPreview = lazy(() => import('group-preview/GroupPreview'))
const MemberGroupPreview = lazy(() => import('member-group-preview/MemberGroupPreview'))

const getLoadingSpinner = (text?: string) => (
  <div className="w-full h-full flex justify-center items-center flex-col bg-gray-50">
    <CircularProgress color="inherit" />
    {text && <span className="mt-4">{text}</span>}
  </div>
)

export const Router: FC = () => {
  const {
    state: { status, user },
  } = useAuth()

  if (status === 'pending') {
    return getLoadingSpinner('Wczytuję dane użytkownika...')
  }

  const leaderRoutes =
    user?.role.name === RoleType.Leader ? (
      <Fragment>
        <Route path="/create-group" element={<GroupCreator />} />
        <Route path="/group/:id" element={<GroupPreview />} />
        <Route path="/episode/:id" element={<EpisodePreview />} />
      </Fragment>
    ) : null

  const memberRoutes =
    user?.role.name === RoleType.Member ? (
      <Fragment>
        <Route path="/find-group" element={<GroupFinder />} />
        <Route path="/group/:id" element={<MemberGroupPreview />} />
        <Route path="/episode/:id" element={<EpisodePreview />} />
      </Fragment>
    ) : null

  return (
    <Suspense fallback={getLoadingSpinner()}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        {leaderRoutes}
        {memberRoutes}
        <Route path="*" element={<Home />} />
      </Routes>
    </Suspense>
  )
}
