import { Grid, TextField } from '@mui/material'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '_shared'
import { Layout } from '_shared/Layout'
import { authService, Credentials } from '../_services/authService'

export const ForgotPassword: FC = () => {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<{ identifier: string }>()

  const [sending, setSending] = useState<boolean>(false)
  const [isSuccessfull, setSuccessfull] = useState<boolean>(false)

  const onSubmit = async ({ identifier }: Credentials) => {
    setSending(true)
    try {
      await authService.forgotPassword(identifier)
    } catch (e) {
    } finally {
      setSending(false)
      setSuccessfull(true)
    }
  }

  return (
    <Layout className="m-4" withBack>
      {!isSuccessfull ? (
        <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              Wprowadź adres email na który zostanie wysłany link do zresetowania hasła.
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="email"
                variant="outlined"
                label="Email"
                {...register('identifier', { required: 'Pole jest wymagane' })}
                error={errors.identifier != null}
                helperText={errors.identifier?.message}
              />
            </Grid>

            <Grid item xs={12}>
              <Button loading={sending} fullWidth type="submit" color="primary" variant="contained">
                Wyślij
              </Button>
            </Grid>
          </Grid>
        </form>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            Jeśli wprowadzony adres email istnieje to został wysłany na niego link do zresetowania hasła.
          </Grid>
        </Grid>
      )}
    </Layout>
  )
}
