import { Message } from 'messages/Message'
import { MESSAGES_TRANSLATIONS } from 'messages/messagesTranslations'
import { isEmpty } from 'ramda'
import { FC } from 'react'

export interface MessagesProps {
  messages: Message[] | undefined
}

export const Messages: FC<MessagesProps> = ({ messages }) => {
  if (!messages || isEmpty(messages)) {
    return null
  }

  return (
    <div className="text-red-500">
      {messages.map((message) => (
        <span key={message.id}>{MESSAGES_TRANSLATIONS[message.id] || 'Wystąpił nieoczekiwany błąd.'}</span>
      ))}
    </div>
  )
}
