import { AxiosResponse } from 'axios'
import { Message } from 'messages/Message'

export interface MessageError {
  messages: Message[]
}

export interface MessageFatalError {
  code: string
  syscall: string
  hostname: string
}

export interface ServerError {
  message: MessageError[] | MessageFatalError
}

export function resolveMessages({ response }: { response: AxiosResponse<ServerError> }): Message[] {
  if (Array.isArray(response?.data?.message)) {
    return response.data.message.map((m) => m.messages).flat()
  }

  return [{ id: 'server.error', message: 'Unexpected server error.' }]
}
