import { Grid, TextField } from '@mui/material'
import { Messages } from 'messages/Messages'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Layout } from '_shared'
import { authService } from '../_services/authService'

interface ResetPasswordForm {
  password: string
}

export const ResetPassword: FC = () => {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<ResetPasswordForm>()

  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')

  const [sending, setSending] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const navigate = useNavigate()

  if (!code) {
    return <Navigate replace to="/" />
  }

  const onSubmit = async ({ password }: ResetPasswordForm) => {
    setSending(true)
    try {
      await authService.resetPassword({ password, passwordConfirmation: password, code })
      navigate('/', { state: { reset_succes: true } })
    } catch (e) {
      setError('Nie udało się zresetować hasła. Spróbuj ponownie wysłać link resetujący hasło.')
    } finally {
      setSending(false)
    }
  }

  return (
    <Layout className="m-4" withBack>
      <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            Wprowadź nowe hasło do swojego konta.
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="password"
              variant="outlined"
              label="Hasło"
              {...register('password', { required: 'Pole jest wymagane' })}
              error={errors.password != null}
              helperText={errors.password?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Button loading={sending} fullWidth type="submit" color="primary" variant="contained">
              Ustaw nowe hasło
            </Button>
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Messages messages={[{ id: '1', message: error }]} />
            </Grid>
          )}
        </Grid>
      </form>
    </Layout>
  )
}
