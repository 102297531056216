import { Diocese } from '_services'
import { axiosInstance } from '_utils'

export interface Parish {
  id: number
  name: string
  diocese: Diocese
  city: string
  createdAt: Date
  updatedAt: Date
}

export const parishService = {
  async create(parish: { name: string; diocese: number; city: string }): Promise<Parish> {
    const { data } = await axiosInstance.post('parishes', parish)
    return data
  },
}
