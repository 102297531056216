import { Episode } from '_services'
import { axiosInstance } from '_utils'

export interface GroupEpisode {
  id: number
  episode: Episode
  note: null | string
  group: number
  visible: boolean
  createdAt: Date
  updatedAt: Date
}

export const groupEpisodeService = {
  async findOne(id: number): Promise<GroupEpisode> {
    const { data } = await axiosInstance.get(`group-episodes/${id}`)
    return data
  },
  async updateNote(id: number, note: string): Promise<void> {
    const { data } = await axiosInstance.post(`group-episodes/${id}/note`, { note })
    return data
  },
  async toggleVisibility(id: number): Promise<void> {
    await axiosInstance.get(`group-episodes/${id}/toggle-visibility`)
  },
}
