import { Message } from 'messages/Message'
import { resolveMessages } from 'messages/resolveMessages'
import { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { authService, Credentials, UserDetails } from '_services'
import { AsyncStatus } from '_utils'

export interface AuthState {
  status: AsyncStatus
  user?: UserDetails
  messages?: Message[]
}

export interface AuthContextType {
  state: AuthState
  login(credentials: Credentials): Promise<void>
  logout(): void
  refresh(): void
}

const AuthContext = createContext<AuthContextType>({} as any)

function AuthProvider(props: any) {
  const navigate = useNavigate()
  const [state, setState] = useState<AuthState>({ status: 'pending' })

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      setState({ status: 'idle' })
      return
    }

    ;(async () => {
      try {
        const user = await authService.userinfo()
        setState({ status: 'success', user, messages: [] })
      } catch (e: any) {
        localStorage.removeItem('token')
        setState({ status: 'fail', user: undefined, messages: resolveMessages(e) })
      }
    })()
  }, [])

  const login = async (credentials: Credentials) => {
    setState({ status: 'pending' })
    try {
      const { jwt, user } = await authService.login(credentials)
      setState({ status: 'success', user, messages: [] })
      localStorage.setItem('token', jwt)
    } catch (e: any) {
      setState({ status: 'fail', user: undefined, messages: resolveMessages(e) })
      throw new Error('Login failed')
    }
  }

  const logout = () => {
    localStorage.removeItem('token')
    setState({ status: 'idle', user: undefined })
    navigate('/')
  }

  const refresh = async () => {
    try {
      const user = await authService.userinfo()
      setState({ status: 'success', user, messages: [] })
    } catch (e: any) {
      localStorage.removeItem('token')
      setState({ status: 'fail', user: undefined, messages: resolveMessages(e) })
    }
  }

  return <AuthContext.Provider value={{ state, login, logout, refresh }} {...props} />
}

const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }
