import { axiosInstance } from '_utils'

export interface DailyText {
  id: number
  text: string
  publishDate: null
  publishedAt: Date
  createdAt: Date
  updatedAt: Date
}

export const dailyTextsService = {
  async fetch(): Promise<DailyText[]> {
    const { data } = await axiosInstance.get('daily-texts')
    return data
  },
}
