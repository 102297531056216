import axios from 'axios'

const getToken = () => localStorage.getItem('token')

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
})

axiosInstance.interceptors.request.use(
  (config) => {
    return {
      ...config,
      headers: {
        ...config.headers,
        ...(getToken() ? { Authorization: `Bearer ${getToken()}` } : {}),
      },
    }
  },
  (error) => {
    return Promise.reject(error)
  },
)
