import { axiosInstance } from '_utils'

export enum RoleType {
  Member = 'Member',
  Leader = 'Leader',
}

export interface RegisterForm {
  name: string
  lastName: string
  email: string
  password: string
  role: RoleType
}

export interface Credentials {
  identifier: string
  password: string
}

export interface Role {
  id: number
  name: RoleType
  description: string
  type: string
}

export interface UserDetails {
  blocked: boolean
  confirmed: boolean
  created_at: string
  email: string
  id: number
  lastName: string
  name: string
  provider: string
  updated_at: string
  role: Role
}

export interface LoginResponse {
  jwt: string
  user: UserDetails
}

export const authService = {
  async register(form: RegisterForm) {
    const { data } = await axiosInstance.post('auth/local/register', form)
    return data
  },
  async login(credentials: Credentials): Promise<LoginResponse> {
    const { data } = await axiosInstance.post('auth/local', credentials)
    return data
  },
  async userinfo(): Promise<UserDetails> {
    const { data } = await axiosInstance.get('users/me')
    return data
  },
  async confirmEmail(token: string): Promise<void> {
    return axiosInstance.get(`auth/email-confirmation?confirmation=${token}`)
  },
  async forgotPassword(email: string): Promise<void> {
    return axiosInstance.post(`auth/forgot-password`, { email })
  },
  async resetPassword(request: { code: string; password: string; passwordConfirmation: string }): Promise<void> {
    return axiosInstance.post(`auth/reset-password`, request)
  },
}
